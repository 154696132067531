//title
#center_column {
  .page_product_box {
    h3 {
      @include sub-heading;
    }
  }
}

//text
p.info-title {
  font-weight: bold;
  color: $base-text-color;
  margin-bottom: 25px;
}

p.info-account {
  margin: -4px 0 24px 0;
}

.dark {
  color: $base-text-color;
}

.main-page-indent {
  margin-bottom: $base-page-indent;
}

.alert {
  font-weight: bold;
  &:before {
    cursor:  pointer;
  }
  ul, ol {
    padding-left: 15px;
    margin-left: 27px;
    li {
      list-style-type: decimal;
      font-weight: normal;
    }
  }
  &.alert-success {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
    &:before {
      font-family: $font-icon;
      content: "\f058";
      font-size: 20px;
      vertical-align: -2px;
      padding-right: 7px;
    }
  }
  &.alert-danger {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
    &:before {
      font-family: $font-icon;
      content: "\f057";
      font-size: 20px;
      vertical-align: -2px;
      padding-right: 7px;
      float: left;
    }
  }
}

.label {
  white-space: normal;
  display: inline-block;
  padding: 6px 10px;
}

label {
  color: $base-title-color;
  &.required {
    &:before {
      content: "*";
      color: red;
      font-size: 14px;
      position: relative;
      line-height: 12px;
    }
  }
}

.unvisible {
  display: none;
}

.checkbox {
  line-height: 16px;
  label {
    color: #777777;
  }
}

.close {
  opacity: 1;
  &:hover {
    opacity: 1;
  }
}
