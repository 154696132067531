@charset "UTF-8";
// Make sure the charset is set appropriately
@import "compass";
@import "compass/reset";
@import "theme_variables";
@import "waynet-utilities";
@import "theme-fonts";
@import "bootstrap";
@import "nice-select";
@import "font-awesome/font-awesome";
@import "icons";
@import "crux-icons";

// theme fundamentals grouped by categories
@import "theme-general";
@import "theme-typography";
@import "theme-structure";
@import "theme-buttons";
@import "theme-form-elements";
@import "order-confirmation";

// theme rest od scss files
@import "my-account";

// fancybox mod
.fancybox-wrap{
	.fancybox-error{
		white-space: normal;
		padding-right: 45px;
	}
}
//table
table.std,
table.table_block {
	margin-bottom: 20px;
	width: 100%;
	border: 1px solid #999999;
	border-bottom: none;
	background: white;
	border-collapse: inherit;
}

table.std th,
table.table_block th {
	padding: 14px 12px;
	font-size: 12px;
	color: $light-text-color;
	font-weight: bold;
	text-transform: uppercase;
	text-shadow: 0 1px 0 black;
	background: #999999;
}

table.std tr.alternate_item,
table.table_block tr.alternate_item {
	background-color: #f3f3f3;
}

table.std td,
table.table_block td {
	padding: 12px;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	font-size: 12px;
	vertical-align: top;
}

.table {
	margin-bottom: 30px;
	> thead {
		> tr {
			> th {
				background: $table-background;
				border-bottom-width: 1px;
				color: $base-text-color;
				vertical-align: middle;
			}
		}
	}
	td {
		ul, ol {
			margin-bottom: 0 !important;
		}
		a.color-myaccount {
			color: $link-color;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	tfoot {
		tr {
			background: $table-background;
		}
	}
}

//product elements
.product-name {
	font-size: 17px;
	line-height: 23px;
	color: $product-name-color;
	margin-bottom: 0;
	a {
		font-size: 17px;
		line-height: 23px;
		color: $product-name-color;
		&:hover {
			color: $product-name-hover-color;
		}
		@media (max-width: $screen-sm) {
			font-size: 14px;
		}
	}
}

.price {
	font-size: 13px;
	color: $price-color;
	white-space: nowrap;
}

.price.product-price {
	font: 600 21px / 26px $font-custom;
	color: $product-price-color;
}

.old-price {
	color: $product-old-price-color;
	text-decoration: line-through;
}

.old-price.product-price {
	font-size: 17px;
}

.special-price {
	color: $product-special-price-color;
}

.price-percent-reduction {
	background: #f13340;
	border: 1px solid #d02a2c;
	font: 600 21px / 24px $font-custom;
	color: $light-text-color;
	padding: 0 5px 0 3px;
	display: inline-block;
	&.small {
		font: 700 14px / 17px $font-family;
		padding: 1px 6px;
	}
}

//Labels New/Sale
.new-box,
.sale-box {
	position: absolute;
	top: 11px;
	text-align: center;
	z-index: 0;
	span{
		font: 300 12px / 31px $font-family;
		text-transform: uppercase;
		text-align: center;
		display: block;
		z-index: 1;
		color: $light-text-color;
		letter-spacing: 0.6px;
	}
}

.new-box {
	right: -22px;
}

.sale-box {
	left: -22px;
}

.new-label {
	background: $crux-blue;
	padding: 0 17px 0 24px;
	@include box-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
}

.sale-label {
	background: $crux-carrot-color;
	padding: 0 24px 0 17px;
	@include box-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
}

.ie8 {
	.new-label {
		left: 0px;
		top: 0px;
		width: auto;
		padding: 5px 15px;
		&:after {
			display: none;
		}
		&:before {
			display: none;
		}
	}
	.sale-label {
		right: 0px;
		top: 0px;
		width: auto;
		padding: 5px 15px;
		&:after {
			display: none;
		}
		&:before {
			display: none;
		}
	}
	.new-box {
		top: -1px;
		left: -1px;
	}
	.sale-box {
		right: -1px;
		top: -1px;
	}
}

//BOXES
.box {
	background: $base-box-bg;
	border: 1px solid $base-border-color;
	padding: $base-box-padding;
	margin: $base-box-indent;
	line-height: 23px;
	p {
		margin-bottom: 0;
	}
	&.box-small {
		padding: 9px 10px 9px 20px;
		.dark {
			padding-top: 10px;
			padding-right: 20px;
			margin-bottom: 0;
			width: auto;
		}
	}
}

.page-product-box {
	padding-bottom: 10px;
}

.product-box {
	width: 178px;
	float: left;
	@include box-sizing(border-box);
	.product-image {
		border: $main_border;
		background: white;
		padding: 5px;
		display: block;
		margin-bottom: 11px;
		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
}

//Homepage Tabs
#home-page-tabs {
	border: none;
	background: $light-background;
	// min 768px
	@media (min-width: $screen-sm) {
		padding: 10px 0;
		margin: 0 0 0 -18px;
	}
	> li {
		margin-bottom: 0;
		// min 768px
		@media (min-width: $screen-sm-max) {
			border-left: 1px solid $base-border-color;
			margin: 0 0px 0 9px;
			padding: 0 0px 0 9px;
		}
		// max 479px
		@media (max-width: $screen-xs - 1) {
			width: 100%;
		}
		a {
			font: 600 21px / 24px $font-custom;
			color: $base-heading-color;
			text-transform: uppercase;
			border: none;
			outline: none;
			margin: 0;
			padding: 10px;
			// min 768px
			@media (min-width: $screen-sm) {
				padding: 1px 10px;
			}
			@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
				padding: 10px 10px;
			}
		}
		&:first-child {
			border: none;
		}
	}
	> li.active a,
	>li a:hover {
		background: $dark-background;
		color: $light-text-color;
		padding: 10px;
		// min 768px
		@media (min-width: $screen-sm) {
			margin: -9px 0;
		}
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			margin: 0px 0;
		}
	}
}

.block {
	margin-bottom: 30px;
	// max 767px
	@media (max-width: $screen-xs-max) {
		margin-bottom: 0px;
	}
	.block_content {
		// max 767px
		@media (max-width: $screen-xs-max) {
			margin-bottom: 20px;
		}
	}
}

.block {
	.title_block,
	h4 {
		font: 600 18px / 22px $font-custom;
		color: $base-heading-color;
		background: #f6f6f6;
		border-top: 5px solid $dark-border-color;
		text-transform: uppercase;
		padding: 14px 5px 17px 20px;
		margin-bottom: 20px;
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			font-size: 14px;
		}
		// max 767px
		@media (max-width: $screen-xs-max) {
			position: relative;
			&:after {
				display: block;
				font-family: $font-icon;
				content: "";
				position: absolute;
				right: 0;
				top: 15px;
				height: 36px;
				width: 36px;
				font-size: 26px;
				font-weight: normal;
			}
			&.active:after {
				content: "";
			}
		}
		a {
			color: $base-heading-color;
			&:hover {
				color: $base-text-color;
			}
		}
	}
	.list-block {
		margin-top: -8px;
		// max 767px
		@media (max-width: $screen-xs-max) {
			margin-top: 0;
		}
		li {
			padding: 5px 0 6px 20px;
			border-top: 1px solid $base-border-color;
			a {
				i {
					display: none;
				}
				&:before {
					content: "\f105";
					display: inline;
					font-family: $font-icon;
					color: $base-text-color;
					padding-right: 8px;
				}
				&:hover {
					color: $base-text-color;
					font-weight: bold;
				}
			}
			&:first-child {
				border-top: none;
			}
		}
		.form-group {
			padding-top: 20px;
			border-top: 1px solid $base-border-color;
			margin-bottom: 0;
			select {
				max-width: 270px;
				// max 767px
				@media (max-width: $screen-xs-max) {
					width: 270px;
				}
			}
		}
		.btn {
			margin-top: 12px;
		}
	}
	.products-block {
		li {
			padding: 0 0 20px 0;
			margin-bottom: 20px;
			border-bottom: 1px solid $base-border-color;
			.products-block-image {
				float: left;
				border: 1px solid $base-border-color;
				margin-right: 19px;
				// min 768px max 991px
				@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
					float: none;
					display: inline-block;
					margin: 0 auto 10px;
					text-align: center;
				}
			}
			.product-content {
				overflow: hidden;
				h5 {
					position: relative;
					top: -11px;
				}
			}
		}
		.product-name {
			font-size: 15px;
			line-height: 18px;
		}
		.product-description {
			margin-bottom: 14px;
		}
		.price-percent-reduction {
			font: 700 14px / 17px $font-family;
			padding: 1px 6px;
		}
	}
}

.page-heading {
	font: 600 18px / 22px $font-custom;
	color: $base-heading-color;
	text-transform: uppercase;
	padding: 0px 0px 17px 0px;
	margin-bottom: 30px;
	border-bottom: 1px solid $base-border-color;
	overflow: hidden;
	span.heading-counter {
		font: bold 13px / 22px $font-family;
		float: right;
		color: $base-text-color;
		text-transform: none;
		margin-bottom: 10px;
		@media (max-width: $screen-xs) {
			float: none;
			display: block;
			padding-top: 5px;
		}
	}
	span.lighter {
		color: #9c9c9c;
	}
	&.bottom-indent {
		margin-bottom: 16px;
	}
	&.product-listing {
		border-bottom: none;
		margin-bottom: 0;
		margin-left: calc( -33.33333% - 15px );
		text-align: center;
		letter-spacing: 2px;
		@media (max-width: $screen-xs-max) {
			margin-left: auto;
		}
		@at-root .hide-left-column &{
			margin-left: 0;
		}
	}
}

.page-subheading {
	font-family: $font-custom;
	font-weight: 600;
	text-transform: uppercase;
	color: $base-heading-color;
	font-size: 18px;
	padding: 0 0 15px;
	line-height: normal;
	margin-bottom: 12px;
	border-bottom: 1px solid $base-border-color;
}

h3.page-product-heading {
	text-align: center;
	color: #333333;
	font-size: 20px;
	font-weight: 300;
	line-height: 20px;
	text-transform: uppercase;
	margin: 4em 0 2em;
}

// BLOCK .footer_links (cms pages)
ul.footer_links {
	padding: 20px 0 0px 0;
	border-top: 1px solid $base-border-color;
	height: 65px;
	li {
		float: left;
		&+li {
			margin-left: 10px;
		}
	}
}

// barre comparaison / tri
.content_sortPagiBar {
	border-bottom: 1px solid $base-border-color;
	margin: 17px 0 99px -30px;
	@at-root .hide-left-column &{
		margin: 17px 0 99px;
	}
	@include xs {
		margin-left: 0;
		float: none;
	}

	.sortPagiBar {
		float: right;
		#productsSortForm {
			float: left;
			margin-right: 20px;
			margin-bottom: 0;
			select {
				max-width: 192px;
				float: left;
				@media (max-width: $screen-sm-max) {
					max-width: 160px;
				}
			}
			.selector {
				float: left;
			}
		}
		.nbrItemPage {
			float: left;
      margin-right: 40px;
			select {
				max-width: 59px;
				float: left;
			}
			.clearfix > span {
				padding: 3px 0 0 12px;
				display: inline-block;
				float: left;
			}
			#uniform-nb_item {
				float: left;
			}
		}
		div.selector{
			border: none;
			height: 32px;
			line-height: 32px;
			width: auto !important;
			span{
				height: 32px;
				line-height: 32px;
				font-size: 15px;
				font-weight: 300;
				width: auto !important;
			}
		}
		label,
		select {
			float: left;
		}
		label{
			padding: 0 6px 0 0;
			margin: 0;
			height: 32px;
			line-height: 32px;
			text-transform: uppercase;
			font-size: 15px;
			font-weight: 300;
		}
		&.instant_search {
			#productsSortForm {
				display: none;
			}
		}
		.pagination{
			margin: 0;
			float: left;
		}
		ul.pagination{
			margin-right: -7px;
		}
	}
	.display,
	.display_m {
		float: right;
		margin-top: -4px;
		li {
			float: left;
			padding-left: 12px;
			text-align: center;
			a {
				color: $GL-text-color;
				font-size: 11px;
				line-height: 14px;
				cursor: pointer;
				i {
					display: block;
					font-size: 24px;
					height: 24px;
					line-height: 24px;
					margin-bottom: -3px;
					color: $GL-icon-color;
				}
				&:hover {
					i {
						color: lighten($GL-icon-color-hover, 30%);
					}
				}
			}
			&.selected {
				a {
					cursor: default;
				}
				i {
					color: $GL-icon-color-hover;
				}
			}
			&.display-title {
				font-weight: bold;
				color: $base-text-color;
				padding: 7px 6px 0 0;
			}
		}
	}
}

//pagination
.top-pagination-content,
.bottom-pagination-content {
	.pagination{
		margin: 0;
	}
	.product-count {
		padding: 11px 0 0 0;
		float: left;
		// max 991px
		@media (max-width: $screen-sm-max) {
			clear: left;
		}
	}
}
.sortPagiBar_counter{
	display: block;
	float: left;
	padding: 0 6px 0 0;
	margin: 0;
	height: 32px;
	line-height: 32px;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 300;
}
#pagination_bottom{
	float: right;
}
.content_sortPagiBar.bottom{
	border-bottom: none;
	margin-bottom: 20px;
}
body.search{
	.top-pagination-content{
		height: 32px;
		overflow: hidden;
	}
}
#product_list + .content_sortPagiBar{
	border-bottom: none;
}

//CART steps
ul.step {
	margin-bottom: 30px;
	overflow: hidden;
	//min 480px
	@media (min-width: $screen-sm) {
		@include border-radius(4px);
	}
	li {
		float: left;
		width: 20%;
		text-align: left;
		border: 1px solid;
		border-top-color: #cacaca;
		border-bottom-color: #9a9a9a;
		border-right-color: #b7b7b7;
		border-left-width: 0px;
		// max 480px
		@media (max-width: $screen-sm - 1) {
			width: 100%;
			border-left-width: 1px;
		}
		a,
		span,
		&.step_current span,
		&.step_current_end span {
			display: block;
			padding: 13px 10px 14px 13px;
			color: $base-text-color;
			font-size: 17px;
			line-height: 21px;
			font-weight: bold;
			text-shadow: 1px 1px rgba(255, 255, 255, 1);
			position: relative;
			// max 991px
			@media (max-width: $screen-md) {
				font-size: 15px;
			}
			// min 991px
			@media (min-width: $screen-md) {
				&:after {
					content: ".";
					position: absolute;
					top: 0;
					right: -31px;
					z-index: 0;
					text-indent: -5000px;
					display: block;
					width: 31px;
					height: 52px;
					margin-top: -2px;
				}
			}
			&:focus {
				text-decoration: none;
				outline: none;
			}
		}
		&.first {
			border-left-width: 1px;
			border-left-color: #b7b7b7;
			//min 480px
			@media (min-width: $screen-sm) {
				@include border-top-left-radius(4px);
				@include border-bottom-left-radius(4px);
			}
			span,
			a {
				z-index: 5;
				padding-left: 13px !important;
				//min 480px
				@media (min-width: $screen-sm) {
					@include border-top-left-radius(4px);
					@include border-bottom-left-radius(4px);
				}
			}
		}
		&.second {
			span, a {
				z-index: 4;
			}
		}
		&.third {
			span, a {
				z-index: 3;
			}
		}
		&.four {
			span, a {
				z-index: 2;
			}
		}
		&.last {
			span {
				z-index: 1;
			}
			//min 480px
			@media (min-width: $screen-sm) {
				@include border-top-right-radius(4px);
				@include border-bottom-right-radius(4px);
				span {
					@include border-top-right-radius(4px);
					@include border-bottom-right-radius(4px);
				}
			}
		}
		&.step_current {
			font-weight: bold;
			background: rgb(66, 184, 86);
			@include gradient-vertical(rgba(66, 184, 86, 1), rgba(67, 171, 84, 1));
			border-color: #399b49 #51ae5c #208931 #369946;
			span {
				color: $light-text-color;
				text-shadow: 1px 1px rgba(32, 137, 49, 1);
				border: 1px solid;
				border-color: #73ca77 #74c776 #74c175 #74c776;
				position: relative;
				//min 991px
				@media (min-width: $screen-md) {
					padding-left: 38px;
					&:after {
						background: url(../img/order-step-a.png) right 0 no-repeat;
					}
				}
			}
		}
		&.step_current_end {
			font-weight: bold;
		}
		&.step_todo {
			background: rgb(247, 247, 247);
			@include gradient-vertical(rgba(247, 247, 247, 1), rgba(237, 237, 237, 1));
			span {
				display: block;
				border: 1px solid;
				border-color: $light-border-color;
				color: $base-text-color;
				position: relative;
				// min 991px
				@media (min-width: $screen-md) {
					padding-left: 38px;
					&:after {
						background: url(../img/order-step-current.png) right 0 no-repeat;
					}
				}
			}
		}
		&.step_done {
			border-color: #666666 #5f5f5f #292929 #5f5f5f;
			background: rgb(114, 113, 113);
			@include gradient-vertical(rgba(114, 113, 113, 1), rgba(102, 102, 102, 1));
			a {
				color: $light-text-color;
				text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
				border: 1px solid;
				border-color: #8b8a8a;
				// min 991px
				@media (min-width: $screen-md) {
					padding-left: 38px;
					&:after {
						background: url(../img/order-step-done.png) right 0 no-repeat;
					}
				}
			}
		}
		&.step_done.step_done_last {
			@media (min-width: $screen-md) {
				a:after {
					background: url(../img/order-step-done-last.png) right 0 no-repeat;
				}
			}
		}
		&#step_end {
			span {
				@media (min-width: $screen-md) {
					&:after {
						display: none;
					}
				}
			}
		}
		em {
			font-style: normal;
			// min 480px max 767px
			@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
				display: none;
			}
		}
	}
}

// module productcomments
.rating {
	clear: both;
	display: block;
	margin: 2em;
	cursor: pointer;
}

.rating:after {
	content: ".";
	display: block;
	height: 0;
	width: 0;
	clear: both;
	visibility: hidden;
}

.cancel, .star {
	overflow: hidden;
	float: left;
	margin: 0 1px 0 0;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.cancel, .cancel a {
	background: url(../../../modules/productcomments/img/delete.gif) no-repeat 0 -16px !important;
}

.cancel a, .star a {
	display: block;
	width: 100%;
	height: 100%;
	background-position: 0 0;
}

div.star_on a {
	background-position: 0 -16px;
}

div.star_hover a,
div.star a:hover {
	background-position: 0 -32px;
}

.pack_content {
	margin: 10px 0 10px 0;
}

.confirmation {
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid #e6db55;
	font-size: 13px;
	background: none repeat scroll 0 0 lightyellow;
}

#page .rte {
	background: transparent none repeat scroll 0 0;

	em {
		font-style: italic !important;
	}
}

.listcomment {
	list-style-type: none;
	margin: 0 0 20px 0 !important;
}

.listcomment li {
	padding: 10px 0;
	border-bottom: 1px dotted $base-border-color;
	color: #666666;
}

.listcomment .titlecomment {
	display: block;
	font-weight: bold;
	font-size: 12px;
	color: $base-text-color;
}

.listcomment .txtcomment {
	display: block;
	padding: 5px 0;
	color: $base-text-color;
}

// BREADCRUMB
.breadcrumb {

	.navigation-pipe {

	}
	a {

		&.home {

			i {

			}
			&:before {

			}
		}
		&:after {

		}
		&:before {

		}
		&:hover {

		}
	}
}

.ie8 {
	.breadcrumb {
		min-height: 1px;
		a:after {
			display: none;
		}
		.navigation-pipe {
			width: 20px;
		}
	}
}

// end of breadcrumbs

#reinsurance_block {
	clear: both !important;
}

// Make sure lists are correctly displayed in tinyMCE BO edition mode too
#short_description_content ul,
#short_description_content ol,
#short_description_content dl,
#tinymce ul,
#tinymce ol,
#tinymce dl {
	margin-left: 20px;
}

.rte ul {
	list-style-type: disc;
	padding-left: 15px;
}

.rte ol {
	list-style-type: decimal;
	padding-left: 15px;
}

.block_hidden_only_for_screen {
	display: none;
}

// PAIMENT - PAGE ORDER

//nav bottom
.cart_navigation {
	margin: 0 0 20px;
	.button-medium {

	}
	.button-exclusive {

	}
}

// step 1 - cart
.cart_last_product {
	display: none;
}

.cart_quantity .cart_quantity_input {

}

.cart_gift_quantity .cart_quantity_input {

}

.table {
	tbody > tr > td {
		vertical-align: middle;
	}
	tfoot > tr > td {
		vertical-align: middle;
	}
}

.cart_delete a.cart_quantity_delete,
a.price_discount_delete {
	font-size: 23px;
	color: $base-text-color;
	&:hover {
		color: silver;
	}
}

//@media (max-width: $screen-xs-max) {
//	#order-detail-content {
//		#cart_summary {
//			table, thead, tbody, th, td, tr {
//				display: block;
//			}
//			thead tr {
//				position: absolute;
//				top: -9999px;
//				left: -9999px;
//			}
//			tr {
//				border-bottom: 1px solid #cccccc;
//				padding-bottom: 7px;
//				overflow: hidden;
//				position: relative;
//			}
//			td {
//				border: none;
//				position: relative;
//				float: left;
//				white-space: normal;
//				padding: 7px 20px 7px 20px;
//				&.cart_product {
//					text-align: left;
//				}
//				&.cart_description {}
//				&.cart_avail {
//					display: none;
//				}
//				&.cart_unit {
//					width: 33%;
//					text-align: right;
//					min-height: 70px;
//					clear: both;
//					.price {
//						text-align: right;
//					}
//				}
//				&.cart_quantity {
//					div {
//						display: block;
//					}
//					&>.form-control {
//						display: inline;
//					}
//					&>.cart_quantity_button a {
//						margin-right: 0px;
//						float: none;
//					}
//					width: 33%;
//					min-height: 70px;
//					border-left: 1px solid #cccccc;
//					border-right: 1px solid #cccccc;
//				}
//				&.cart_total {
//					width: 33%;
//					min-height: 70px;
//					text-align: right;
//				}
//				&.cart_delete {
//					position: absolute;
//					right: 20px;
//					top: 7px;
//					&:before {
//						content: '' !important;
//					}
//				}
//				div {
//					display: inline;
//				}
//			}
//			td:before {
//				content: attr(data-title);
//				font-weight: bold;
//				display: block;
//			}
//			tfoot {
//				td {
//					float: none;
//					width: 100%;
//					&:before {
//						display: inline;
//					}
//				}
//				tr {
//					.text-right, .price {
//						display: block;
//						float: left;
//						width: 50%;
//					}
//				}
//			}
//		}
//	}
//}

@media (max-width: $screen-sm) {
	#order-detail-content {
		#cart_summary {
			tbody {
				td {
					.price {
						text-align: center;
					}
					&.cart_description {
						width: 300px;
					}
				}
			}
		}
	}
}

.cart_voucher {
	vertical-align: top !important;
	h4 {
		font: 600 18px / 22px $font-custom;
		color: $base-heading-color;
		text-transform: uppercase;
		padding: 7px 0 10px 0;
	}
	.title-offers {
		color: $base-text-color;
		font-weight: bold;
		margin-bottom: 6px;
	}
	fieldset {
		margin-bottom: 10px;
		#discount_name {
			float: left;
			width: 219px;
			margin-right: 11px;
		}
	}
	#display_cart_vouchers {
		span {
			font-weight: bold;
			cursor: pointer;
			color: $link-color;
			&:hover {
				color: $link-hover-color;
			}
		}
	}
}

.enable-multishipping {
	margin: -13px 0 17px;
	label {
		font-weight: normal;
	}
}

#HOOK_SHOPPING_CART {
	#loyalty {
		i {
			font-size: 26px;
			line-height: 26px;
			color: #cfcccc;
		}
	}
}

#order_carrier {
	clear: both;
	margin-top: 20px;
	border: 1px solid #999999;
	background: $light-background;
}

.order_carrier_logo {
  max-width: 65px;
  max-height: 65px;
}

#order_carrier .title_block {
	padding: 0 15px;
	height: 29px;
	font-weight: bold;
	line-height: 29px;
	color: $light-text-color;
	font-weight: bold;
	text-transform: uppercase;
	background: url(../img/bg_table_th.png) repeat-x 0 -10px #999999;
}

#order_carrier span {
	display: block;
	padding: 15px;
	font-weight: bold;
}

.multishipping_close_container {
	text-align: center;
}

table#cart_summary .gift-icon {
	color: white;
	background: #0088cc;
	display: inline;
	line-height: 20px;
	padding: 2px 5px;
	border-radius: 5px;
}

// step 3 - address
#multishipping_mode_box {
	padding-top: 12px;
	padding-bottom: 19px;
	.title {
		font-weight: bold;
		color: $base-text-color;
		margin-bottom: 15px;
	}
	.description_off {
		display: none;
		div {
			margin-bottom: 10px;
		}
	}
	.description_off a:hover,
	.description a:hover {
		background: #f3f3f3;
		border: 1px solid #cccccc;
	}
}

#multishipping_mode_box.on .description_off {
	display: block;
}

.multishipping-cart {
	.cart_address_delivery {
		&.form-control {
			width: 198px;
		}
	}
}

#order .address_add.submit {
	margin-bottom: 20px;
}

#ordermsg {
	margin-bottom: 30px;
}

#id_address_invoice.form-control,
#id_address_delivery.form-control {
	width: 269px;
}

.addresses {
	.select label,
	.selector,
	.addresses .address_select {
		clear: both;
		float: left;
	}
	.waitimage {
		display: none;
		float: left;
		width: 24px;
		height: 24px;
		background-image: url("../../../img/loader.gif");
		background-repeat: no-repeat;
		position: relative;
		bottom: -2px;
		left: 10px;
	}
	.checkbox.addressesAreEquals {
		clear: both;
		float: left;
		margin: 15px 0 25px 0;
	}
}

// step 4 - paiement
#order .delivery_option,
#order-opc .delivery_option {
	> div {
		display: block;
		margin-bottom: 20px;
		font-weight: normal;
		> table {
			background: $light-background;
			margin-bottom: 0;
			&.resume {
				height: 53px;
				&.delivery_option_carrier {
					margin-top: 10px;
					width: 100%;
					border: 1px solid $table-border-color;
					font-weight: bold;
					td {
						padding: 8px 11px 7px 11px;
						i {
							font-size: 20px;
							margin-right: 7px;
							vertical-align: -2px;
						}
					}
				}
				td {
					&.delivery_option_radio {
						width: 54px;
						padding-left: 0;
						padding-right: 0;
						text-align: center;
					}
					&.delivery_option_logo {
						width: 97px;
						padding-left: 21px;
					}
					&.delivery_option_price {
						width: 162px;
					}
				}
			}
		}
	}
}

.order_carrier_content {
	line-height: normal;
	padding-bottom: 16px;
	.carrier_title {
		font-weight: bold;
		color: $base-text-color;
	}
	.checkbox {
		input {
			margin-top: 2px;
		}
	}
	.delivery_options_address {
		.carrier_title {
			margin-bottom: 17px;
		}
	}
	& + div {
		padding-bottom: 15px;
	}
}

#carrierTable {
	border: 1px solid #999999;
	border-bottom: none;
	background: $light-background;
}

#carrierTable tbody {
	border-bottom: 1px solid #999999;
}

#carrierTable th {
	padding: 0 15px;
	height: 29px;
	font-weight: bold;
	line-height: 29px;
	color: $light-text-color;
	text-transform: uppercase;
	background: url(../img/bg_table_th.png) repeat-x 0 -10px #999999;
}

#carrierTable td {
	padding: 15px;
	font-weight: bold;
	border-right: 1px solid #e9e9e9;
}

#carrierTable td.carrier_price {
	border: none;
}

p.checkbox.gift {
	margin-bottom: 10px;
}

#gift_div {
	display: none;
	margin-top: 5px;
	margin-bottom: 10px;
}

a.iframe {
	color: $base-text-color;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

//step 5 - paiement
p.payment_module {
	margin-bottom: 10px;
	a {
		display: block;
		border: 1px solid $table-border-color;
		@include border-radius(4px);
		font-size: 17px;
		line-height: 23px;
		color: $base-text-color;
		font-weight: bold;
		padding: 33px 40px 34px 99px;
		letter-spacing: -1px;
		position: relative;
		&.bankwire {
			background: url(../img/bankwire.png) 15px 12px no-repeat $base-box-bg;
		}
		&.cheque {
			background: url(../img/cheque.png) 15px 15px no-repeat $base-box-bg;
		}
		&.cash {
			background: url(../img/cash.png) 15px 15px no-repeat $base-box-bg;
		}
		&.cheque, &.bankwire, &.cash {
			&:after {
				display: block;
				content: "\f054";
				position: absolute;
				right: 15px;
				margin-top: -11px;
				top: 50%;
				font-family: $font-icon;
				font-size: 25px;
				height: 22px;
				width: 14px;
				color: $link-color;
			}
		}
		&:hover {
			background-color: #f6f6f6;
		}
		span {
			font-weight: bold;
			color: $base-body-color;
		}
	}
}

.payment_module.box {
	margin-top: 20px;
}

p.cheque-indent {
	margin-bottom: 9px;
}

.cheque-box {
	.form-group {
		margin-bottom: 0px;
		.form-control {
			width: 269px;
		}
	}
}

.order-confirmation.box {
	line-height: 29px;
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

//PAGE ORDER-OPC
.delivery_option_carrier td {
	width: 200px;
}

.delivery_option_carrier td + td {
	width: 280px;
}

.delivery_option_carrier td + td + td {
	width: 200px;
}

.delivery_option_carrier tr td {
	padding: 5px;
}

.delivery_option_carrier.selected {
	display: table;
}

.not-displayable {
	display: none;
}

//PAGES ADRESSES, IDENTITY
#address .box {
	background: none;
	border: none;
}
#address,
#identity,
#account-creation_form,
#authentication {
	.box {
		padding-bottom: 20px;
		line-height: 20px;
	}
	p.required {
		color: #f13340;
		margin: 9px 0 16px 0;
	}
	p.inline-infos {
		color: red;
	}
	.form-group {
		margin-bottom: 20px;
		.form-control {
			max-width: 302px;
			&#adress_alias {
				margin-bottom: 20px;
			}
		}
		&.phone-number {
			// min 1200px
			@media (min-width: $screen-lg) {
				float: left;
				width: 302px;
				margin-right: 13px;
			}
			+ p {
				@media (min-width: $screen-lg) {
					padding: 23px 0 0 0px;
					margin-bottom: 0;
				}
			}
		}
	}
	.gender-line {
		margin-bottom: 4px;
		padding-top: 4px;
		> label {
			margin-right: 10px;
			margin-bottom: 0;
		}
		.radio-inline {
			label {
				font-weight: normal;
				color: #777777;
			}
		}
	}
}

#identity,
#authentication,
#order-opc {
	#center_column {
		form.std {
			.row {
				margin-left: -5px;
				margin-right: -5px;
				.col-xs-4 {
					padding-left: 5px;
					padding-right: 5px;
					max-width: 94px;
					.form-control {
						max-width: 84px;
					}
				}
			}
		}
		.footer_links {
			border: none;
			padding-top: 0;
		}
	}
}

#new_account_form .box {
	line-height: 20px;
	.date-select {
		padding-bottom: 10px;
	}
	.top-indent {
		padding-top: 10px;
	}
	.customerprivacy {
		padding-top: 15px;
	}
}

#authentication {
	fieldset.account_creation {
		margin-bottom: 20px;
	}
}

// PASSWORD
#password {
	.box {
		p {
			margin-bottom: 9px;
		}
		.form-group {
			margin-bottom: 20px;
			.form-control {
				width: 263px;
			}
		}
	}
	.footer_links {}
	padding-top: 0;
	border: none;
}

//PAGE SEARCH
#instant_search_results {
	a.close {
		font-size: 13px;
		font-weight: 600;
		opacity: 1;
		line-height: inherit;
		text-transform: none;
		font-family: $font-family;
		&:hover {
			color: $link-hover-color;
		}
	}
}

// MANUFACTURERS/SUPPLIERS
#manufacturers_list,
#suppliers_list {
	h3 {
		font-size: 17px;
		line-height: 23px;
	}
	.description {
		line-height: 18px;
	}
	.product-counter {
		a {
			font-weight: bold;
			color: $base-text-color;
			&:hover {
				color: $link-hover-color;
			}
		}
	}
	&.list {
		li {
			.mansup-container {
				border-top: 1px solid $base-border-color;
				padding: 31px 0 30px 0;
				// < 768
				@media (max-width: $screen-xs-max) {
					text-align: center;
				}
			}
			&:first-child {
				.mansup-container {
					border-top: 0;
				}
			}
			.left-side {
				text-align: center;
			}
			h3 {
				margin-top: -5px;
				padding-bottom: 8px;
			}
			.middle-side {
				padding-left: 0;
				// < 768
				@media (max-width: $screen-xs-max) {
					padding-right: 15px;
					padding-left: 15px;
				}
			}
			.right-side-content {
				border-left: 1px solid $base-border-color;
				padding: 0 0 32px 31px;
				min-height: 108px;
				// < 768
				@media (max-width: $screen-xs-max) {
					min-height: 1px;
					padding: 0;
					border: none;
				}
				.product-counter {
					position: relative;
					top: -6px;
					margin-bottom: 12px;
					// < 768
					@media (max-width: $screen-xs-max) {
						top: 0;
					}
				}
			}
		}
	}
	&.grid {
		li {
			text-align: center;
			.product-container {
				padding-top: 40px;
				padding-bottom: 20px;
				border-bottom: 1px solid $base-border-color;
				.left-side {
					padding-bottom: 42px;
				}
				h3 {
					padding-bottom: 10px;
				}
				.product-counter {
					margin-bottom: 12px;
				}
			}
		}
		// > 1199
		@media (min-width: $screen-lg) {
			li.first-in-line {
				clear: left;
			}
			li.last-line {
				.product-container {
					border-bottom: none;
				}
			}
		}
		//768 -> 1199
		@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
			li.first-item-of-tablet-line {
				clear: left;
			}
			li.last-tablet-line {
				.product-container {
					border-bottom: none;
				}
			}
		}
		// < 768
		@media (max-width: $screen-xs-max) {
			li.item-last {}
			.product-container {
				border-bottom: none;
			}
		}
	}
}

#manufacturer,
#supplier {
	.description_box {
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid $base-border-color;
		.hide_desc {
			display: none;
		}
	}
	.bottom-pagination-content,
	.top-pagination-content {
		min-height: 69px;
	}
	.top-pagination-content.bottom-line {
		border-bottom: 1px solid $base-border-color;
	}
}

//DISCOUNT PAGE
table.discount {
	i {
		font-size: 20px;
		line-height: 20px;
		vertical-align: -2px;
		&.icon-ok {
			color: #46a74e;
		}
		&.icon-remove {
			color: #f13340;
		}
	}
}

//GUEST TRACKING
#guestTracking {
	.form-control {
		max-width: 271px;
	}
	// > 1200px
	@media (min-width: $screen-lg) {
		.form-group {
			overflow: hidden;
			i {
				padding-left: 10px;
			}
		}
		.form-control {
			float: left;
		}
		label {
			float: left;
			clear: both;
			width: 100%;
		}
	}
}

//PAGE 404
#pagenotfound {
	.pagenotfound {
		max-width: 824px;
		margin: 100px auto 100px auto;
		text-align: center;
		.img-404 {
			padding: 8px 0 27px 0;
			img {
				max-width: 100%;
			}
		}
		h1 {
			font: 600 28px / 34px $font-custom;
			color: $base-text-color;
			text-transform: uppercase;
			margin-bottom: 7px;
		}
		p {
			font: 600 16px / 20px $font-custom;
			color: #555454;
			text-transform: uppercase;
			border-bottom: 1px solid $base-border-color;
			padding-bottom: 19px;
			margin-bottom: 20px;
		}
		h3 {
			font-weight: bold;
			color: $base-text-color;
			font-size: 13px;
			line-height: normal;
			margin-bottom: 18px;
		}
		label {
			font-weight: normal;
			@media (max-width: $screen-xs-max) {
				display: block;
			}
		}
		.form-control {
			max-width: 293px;
			display: inline-block;
			margin-right: 5px;
			// max 767px
			@media (max-width: $screen-xs-max) {
				margin: 0 auto 15px auto;
				display: block;
			}
		}
		.buttons {
			padding: 48px 0 20px 0;
			.button-medium {
				i.left {
					font-size: 17px;
				}
			}
		}
	}
}

//addon RerversoForm
#account-creation_form fieldset.reversoform {
	padding: 10px !important;
}

#account-creation_form fieldset.reversoform .text label {
	display: block;
	padding: 0 0 10px 0;
	width: 100%;
	text-align: left;
}

#account-creation_form fieldset.reversoform .infos-sup {
	padding: 0 !important;
	font-size: 10px;
	font-style: italic;
	text-align: right;
}

//addon customer privacy
#account-creation_form fieldset.customerprivacy label,
fieldset.customerprivacy label {
	cursor: pointer;
}

//addon referralprogram
.tab-content {
	margin-top: 20px;
}

//addons comments products
form#sendComment fieldset {
	padding: 10px;
	border: 1px solid #cccccc;
	background: #eeeeee;
}

form#sendComment h3 {
	font-size: 14px;
}

#new_comment_form p.text {
	margin-bottom: 0;
	padding-bottom: 0;
}

#sendComment p.text label,
#sendComment p.textarea label {
	display: block;
	margin: 12px 0 4px;
	font-weight: bold;
	font-size: 12px;
}

#sendComment p.text input {
	padding: 0 5px;
	height: 28px;
	width: 498px;
	border: 1px solid #cccccc;
	background: url(../img/bg_input.png) repeat-x 0 0 $light-background;
}

#sendComment p.textarea textarea {
	height: 80px;
	width: 508px;
	border: 1px solid #cccccc;
}

#sendComment p.submit {
	padding: 0;
	text-align: right;
}

#sendComment p.closeform {
	float: right;
	padding: 0;
	height: 12px;
	width: 12px;
	text-indent: -5000px;
	background: url(../img/icon/delete.gif) no-repeat 0 0;
}

.star {
	position: relative;
	top: -1px;
	float: left;
	width: 14px;
	overflow: hidden;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
}

.star {
	display: block;
	a {
		display: block;
		position: absolute;
		text-indent: -5000px;
	}
}

div.star {
	&:after {
		content: "\f006";
		font-family: $font-icon;
		display: inline-block;
		color: #777676;
	}
}

div.star.star_on {
	display: block;
	&:after {
		content: "\f005";
		font-family: $font-icon;
		display: inline-block;
		color: #ef8743;
	}
}

div.star.star_hover {
	&:after {
		content: "\f005";
		font-family: $font-icon;
		display: inline-block;
		color: #ef8743;
	}
}

//CSS Modules
//IMPORTANT - Internet Explorer can read 31 CSS files max. Some CSS have been put here and erased from their own module folder.

//Block ADVERTISING
.advertising_block {
	width: 191px;
	margin-bottom: 1em;
	text-align: center;
}

//footer
.blockcategories_footer ul ul {
	display: none !important;
}

//Form validate
.form-group {
	&.form-error {
		input, textarea {
			border: 1px solid #c1222d;
			color: #c1222d;
		}
	}
	&.form-ok {
		input, textarea {
			/*border: 1px solid #46a74e;
			color: #35b33f;*/
		}
	}
	&.form-error, &.form-ok {
		.form-control {
			padding-right: 30px;
		}
	}
}

//uniform
.radio-inline, .checkbox {
	padding-left: 0;
	.checker {
		float: left;
		span {
			top: 0px;
		}
	}
	div.radio {
		display: inline-block;
		span {
			float: left;
			top: 0px;
		}
	}
}

.fancybox-overlay{
	background: rgba(29, 49, 75, 0.5) !important;
}

.fancybox-skin {
	background: $light-background !important;
	@include border-radius(0 !important);
}

.fancybox-skin .fancybox-close {
	width: 28px;
	height: 28px;
	background: none;
	font-size: 28px;
	line-height: 28px;
	color: $base-text-color;
	text-align: center;
	background: white;
	@include border-radius(0);
	top: 5px;
	right: 8px;
	&:hover {
		color: $link-hover-color;
	}
	&:after {
		content: '\e003';
		font: normal 16px 'CRUX';
	}
}

//Homepage pre-footer
#facebook_block,
#cmsinfo_block {
	overflow: hidden;
	background: $homepage-footer-bg;
	min-height: 344px;
	height: 344px;
	padding-right: 29px;
	padding-left: 29px;
	// max 991px
	@media (max-width: $screen-sm-max) {
		min-height: 354px;
		height: 354px;
		padding-left: 13px;
		padding-right: 13px;
	}
	// max 767px
	@media (max-width: $screen-xs-max) {
		width: 100%;
		min-height: 1px;
	}
}

#facebook_block {
	h4 {
		padding: 35px 0 0 0;
		margin: 0 0 12px 0;
		font: 300 21px / 25px $font-custom;
		color: lighten($base-heading-color, 10%);
		// max 991px
		@media (max-width: $screen-sm-max) {
			font-size: 18px;
		}
		// max 767px
		@media (max-width: $screen-xs-max) {
			padding-top: 20px !important;
		}
		// 768px
		@media (max-width: $screen-sm) {
			font-size: 16px;
		}
	}
	.facebook-fanbox {
		background: $light-background;
		border: 1px solid #aaaaaa;
		padding-bottom: 10px;
	}
}

#cmsinfo_block {
	border-left: 1px solid $homepage-footer-border;
	// max 767px
	@media (max-width: $screen-xs-max) {
		border: none;
		margin-top: 10px;
	}
	> div {
		padding: 35px 10px 0 0;
		// max 767px
		@media (max-width: $screen-xs-max) {
			padding-top: 20px;
		}
		// max 479px
		@media (max-width: $screen-xs - 1) {
			width: 100%;
			border-top: 1px solid $homepage-footer-border;
		}
	}
	> div + div {
		border-left: 1px solid $homepage-footer-border;
		min-height: 344px;
		padding-left: 29px;
		// max 479px
		@media (max-width: $screen-xs - 1) {
			border-left: none;
			padding-left: 10px;
			min-height: 1px;
			padding-bottom: 15px;
		}
	}
	em {
		float: left;
		width: 60px;
		height: 60px;
		margin: 3px 10px 0 0;
		font-size: 30px;
		color: $light-text-color;
		line-height: 60px;
		text-align: center;
		background: lighten($base-heading-color, 10%);
		@include border-radius(100px);
		// max 991px
		@media (max-width: $screen-sm-max) {
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 20px;
		}
	}
	.type-text {
		overflow: hidden;
	}
	h3 {
		margin: 0 0 5px 0;
		font: 300 21px / 25px $font-custom;
		color: lighten($base-heading-color, 10%);
		// max 1199px
		@media (max-width: $screen-md-max) {
			font-size: 18px;
		}
	}
	ul {
		li {
			padding-bottom: 22px;
			// max 1199px
			@media (max-width: $screen-md-max) {
				padding-bottom: 10px;
			}
			// max 991px
			@media (max-width: $screen-sm-max) {
				padding-bottom: 0;
			}
		}
	}
	p {
		em {
			background: none;
			@include border-radius(0);
			margin: 0;
			font-size: 13px;
			color: $text-color;
			float: none;
			height: inherit;
			line-height: inherit;
			text-align: left;
			font-style: italic;
		}
	}
}

.ie8 {
	#facebook_block,
	#cmsinfo_block {
		height: 344px;
	}
}

//Zoom
// max 1199px
@media (max-width: $screen-md-max) {
	.zoomdiv {
		display: none !important;
	}
}

//temporary fix for quickview
#product.content_only {
	div.pb-left-column {
		p.resetimg {
			margin-bottom: 0px;
			padding-top: 0px;
		}
		#thumbs_list_frame {
			margin-bottom: 0px;
		}
	}
}

//Product Compare
#product_comparison {
	td.compare_extra_information {
		padding: 10px;
	}
	#social-share-compare {
		margin-bottom: 50px;
	}
}

//Social Sharing
.btn-facebook, .btn-twitter, .btn-google-plus, .btn-pinterest {
	border: none;
	background: transparent;
	&:hover {
		border-color: white;
		background: transparent;
	}
}

.btn-facebook {
	i {
		color: #333;
	}
	&:hover {
		color: #435f9f;
		i {
			color: #435f9f;
		}
	}
}

.btn-twitter {
	i {
		color: #333;
	}
	&:hover {
		color: #00aaf0;
		i {
			color: #00aaf0;
		}
	}
}

.btn-google-plus {
	i {
		color: #333;
	}
	&:hover {
		color: #e04b34;
		i {
			color: #e04b34;
		}
	}
}

.btn-pinterest {
	i {
		color: #333;
	}
	&:hover {
		color: #ce1f21;
		i {
			color: #ce1f21;
		}
	}
}

.payment_option_form {
  display: none;
}

.payment_option_logo {
  height:50px;
  max-height: 50px;
}

.payment_module_adv {
  height:120px;
  max-height: 120px;
}

.payment_option_selected {
    i {
      color: green;
    }
    display: none;
    margin-top: 15px;
}

.payment_option_cta {
  margin-left: 10px;
  color: #333 !important;
}

.tnc_box {
  background: #fbfbfb;
  border: 1px solid #d6d4d4;
  padding: 14px 18px 13px;
  margin: 0 0 30px 0;
  line-height: 23px;
}

.pointer-box {
  cursor: pointer;
}

// custom cms pages (about-us)


.about-us {
	.as-img-holder {
		@media (min-width: $screen-xs) {
			padding-top: 28px;
		}
		@include xs {
			padding-top: 56px;
		}
	}
	.as-description {
		max-width: 500px;
      @media (max-width: $screen-sm-max) {
        max-width: 100%;
      }
		p {
			color: #333333;
			font-family: $font-custom;
			font-size: 12px;
			font-weight: 400;
			line-height: 22px !important;
		}
		p:first-of-type:first-letter {
			float: left;
			text-transform: uppercase;
			color: #333333;
			font-family: $font-custom;
			font-size: 70px;
			font-weight: 400;
			margin-bottom: 22px;
			margin-left: -5px;
			margin-right: 10px;
		}
	}
	.as-image-container {
		border: 2px solid #3d6693;
		position: relative;
		max-width: 550px;
		max-height: 545px;
		-webkit-box-shadow: inset 0px 0px 5px 3px rgba(221,221,221,0.85);
		-moz-box-shadow: inset 0px 0px 5px 3px rgba(221,221,221,0.85);
		box-shadow: inset 0px 0px 5px 3px rgba(221,221,221,0.85);
		@media (min-width: $screen-xs) {
			padding-top: 0px;
			float: right;
		}
		@include xs {
			float: none;
			margin: 0px auto;
			max-width: 100%;
			max-height: 100%;
		}

	}
	.as-image {
		position: relative;
		top: -30px;
		left:-20px;
		@media (min-width: $screen-md) {
			width: 550px;
			height: 545px !important;
		}
		margin: 0 !important;
		@include xs {
			top:0;
			left:0;
			width: 100%;

		}
	}

}

.regulations {
	h2 {
		font-size: 15px;
		font-weight: normal;
		text-decoration: none;
		text-transform: uppercase;
		color: #000;
		em {
			font-style: unset;
		}
	}
	h2:not(:first-of-type) {
		margin-top: 30px !important;
	}
	p {
		color: #000;
		line-height: 29px !important;
		font-size: 12px;
		padding: 0 0 0 5px;
	}
	.page-heading-cms {
		margin-bottom: 10px !important;
	}
}

.cms-page-clinks {
	margin-top: 176px;
	margin-bottom: 85px;

	.nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0px;
		li {
			width: 24%;
			@include xs {
				width: 100%;
				margin: 0 0 15px 0;
			}
			& > a {
				color: #333333;
				font-family: $font-custom;
				font-size: 18px;
				font-weight: 300;
				line-height: 36px;
				text-transform: uppercase;
				box-shadow: 0 3px 3px rgba(51, 51, 51, 0.2);
				border: 2px solid #e86342;
				background-color: #ffffff;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				padding: 22px 0;
				&:hover {
					background-color: #FFF;
					color: #333333;
				}
			}
		}

	}
	.cpc-holder {
		@media (min-width: $screen-md) {
			padding: 28px;
		}
	}

}

.page-heading-cms {
	text-align: center;
	border: none;
	font-size: 24px;
	margin: 65px 0 55px 0 !important;
	color: #000;
	text-transform: uppercase;
	overflow: hidden;
	box-sizing: border-box;

}
