//form
.form-control {
  padding: 3px 5px;
  height: 45px;
  @include box-shadow(none);
  @include border-radius(0);
  &.grey {
    background: $base-box-bg;
  }
}
input.uniform-input{
  &[type="text"],
  &[type="email"],
  &[type="password"]{
    font-size: 11px;
    font-weight: 300;
    line-height: 38px;
    color: #333;
    border: solid 1px #c8c8c8;
    padding: 0 16px;
  }
}
input[type="text"],
input[type="email"],
input[type="password"]{
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  height: auto;
  line-height: 38px;
  padding: 0 16px;
  width: 400px;
  max-width: 100%;
  color: #666;
  font-size: 11px;
  font-weight: 300;
  @include w-placeholder{
    opacity: 1;
    color: #c8c8c8;
  }
}

// custom styled form - bigger elements
.std-form{
  input[type="text"],
  input[type="email"],
  input[type="password"]{
    line-height: 43px;
    border: 1px solid #b7b7b7;
    padding: 0 13px;
    width: 302px;
    max-width: 100%;
    color: #666;
    font-size: 12px;
    font-weight: 300;
    height: auto;
  }
  .form-group {
    margin-bottom: 20px;
  }
}

.checkbox-label{
  position: relative;
  padding: 0 0 8px 20px;
  font-size: 11px;
  font-weight: 300;
  line-height: 19px;
  div.checker {
    border: 1px solid #000;
    width: 13px;
    height: 13px;
    background: transparent;
    position: absolute;
    left: 0;
    top: 3px;
    span {
      background-image: none !important;
      background: transparent;
      @include absolute_stretch;
      input {
        @include absolute_stretch;
        min-width: 1px;
        min-height: 1px;
        z-index: 5;
        &:hover {
          cursor: pointer;
        }
      }
      &.checked {
        &:after {
          content: '\f00c';
          @include absolute_stretch;
          z-index: 4;
          text-align: center;
          color: #fff;
          font: normal 8px/11px $font-icon;
          text-indent: 0;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
/* color versions */
.checkbox-label.white{
  color: #fff;
  opacity: 0.8;
  a{
    text-decoration: underline;
  }
  div.checker {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border: 1px solid #ffffff;
  }
}