@font-face {
  font-family: 'CRUX';
  src: url('../fonts/crux/CRUX.eot');
  src: url('../fonts/crux/CRUX.eot?#iefix') format('embedded-opentype'),
  url('../fonts/crux/CRUX.woff2') format('woff2'),
  url('../fonts/crux/CRUX.woff') format('woff'),
  url('../fonts/crux/CRUX.ttf') format('truetype'),
  url('../fonts/crux/CRUX.svg#CRUX') format('svg');
  font-weight: normal;
  font-style: normal;
}

$def-crux-font-size:             14px !default;
$def-crux-font-size-short:       20px !default;

[class^="crux-"]:after{
  font-family: 'CRUX';
  font-size: $def-crux-font-size;
  font-weight: normal;
}
.crux-cross:after{        content: '\e003';  }
.crux-right-long:after{        content: '\e025';  }
.crux-left-long:after{        content: '\e027';  }
.crux-right:after{        content: '\e02B';  }
.crux-left:after{        content: '\e02D';  }




.crux-caret-down{
  &:after{
    content: '';
    position: relative;
    top: -3px;
    @include arrow_border(down, 1px, 7px, 7px);
  }
}
.crux-caret-up{
  &:after{
    content: '';
    position: relative;
    @include arrow_border(up, 1px, 7px, 7px);
  }
}

.img-ico-delivery{
  display: inline-block;
  width: 30px;
  img{
    max-width: 100%;
    height: auto;
  }
}
.img-ico-info-b{
  display: inline-block;
  width: 13px;
  img{
    max-width: 100%;
    height: auto;
  }
}