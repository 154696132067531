//@font-face {font-family: "Kaleko 105 Round W01 Book";
//  src: url("../fonts/bd7b8dff70070f799e47d0f16084662f.eot"); /* IE9*/
//  src: url("../fonts/bd7b8dff70070f799e47d0f16084662f.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/bd7b8dff70070f799e47d0f16084662f.woff2") format("woff2"), /* chrome、firefox */
//  url("../fonts/bd7b8dff70070f799e47d0f16084662f.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/bd7b8dff70070f799e47d0f16084662f.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/bd7b8dff70070f799e47d0f16084662f.svg#Kaleko 105 Round W01 Book") format("svg"); /* iOS 4.1- */
//}

@font-face {
  font-family: 'Kaleko 105 Round W01 Book';
  src: url('../fonts/kaleko/kaleko_105_round_book-webfont.woff2') format('woff2'),
  url('../fonts/kaleko/kaleko_105_round_book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Kaleko 105 Round W01 Book';
  src: url('../fonts/kaleko/kaleko_105_round_book_oblique-webfont.woff2') format('woff2'),
  url('../fonts/kaleko/kaleko_105_round_book_oblique-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}


//@font-face {
//  font-family: 'kaleko_105_roundregular';
//  src: url('../fonts/kaleko/kaleko_105_round_thin-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_thin-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'kaleko_105_rounditalic';
//  src: url('../fonts/kaleko/kaleko_105_round_thin_oblique-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_thin_oblique-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'kaleko_105_roundregular';
//  src: url('../fonts/kaleko/kaleko_105_round_light-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_light-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//
//}
//@font-face {
//  font-family: 'kaleko_105_rounditalic';
//  src: url('../fonts/kaleko/kaleko_105_round_light_oblique-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_light_oblique-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'kaleko_105_roundbold';
//  src: url('../fonts/kaleko/kaleko_105_round_bold-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_bold-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'kaleko_105_roundbold_italic';
//  src: url('../fonts/kaleko/kaleko_105_round_bold_oblique-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_bold_oblique-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'kaleko_105_roundheavy';
//  src: url('../fonts/kaleko/kaleko_105_round_heavy-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_heavy-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'kaleko_105_roundheavy_oblique';
//  src: url('../fonts/kaleko/kaleko_105_round_heavy_oblique-webfont.woff2') format('woff2'),
//  url('../fonts/kaleko/kaleko_105_round_heavy_oblique-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}