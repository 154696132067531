//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  padding: 12px 0 8px;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;
  text-transform: uppercase;
  color: $breadcrumb-color;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.3px;
  span.navigation-pipe {
    display: inline-block;
    width: 12px;
    margin-right: 4px;
    text-align: center;
  }
  > li {
    display: inline-block;
    &+li:before {
      content: "/\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $breadcrumb-color;
    }
  }
  > .active {
    color: $breadcrumb-active-color;
  }
}
