/* flex helper */
@for $i from 1 through 10{
  .fg-#{$i}{
    -webkit-flex-grow: $i;
    flex-grow: $i;
  }
}
.fg-20{
  -webkit-flex-grow: 20;
  flex-grow: 20;
}
.fg-25{
  -webkit-flex-grow: 25;
  flex-grow: 25;
}
.fg-30{
  -webkit-flex-grow: 30;
  flex-grow: 30;
}
.fg-40{
  -webkit-flex-grow: 40;
  flex-grow: 40;
}
.fg-70{
  -webkit-flex-grow: 70;
  flex-grow: 70;
}
.fg-80{
  -webkit-flex-grow: 80;
  flex-grow: 80;
}
.flex{
  display: flex;
  &.dir-col{
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
  }
  &.sb{
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  &.sa{
    justify-content: space-around;
    -webkit-justify-content: space-around;
  }
  &.h-cen{
    justify-content: center;
    -webkit-justify-content: center;
  }
  &.start{
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
  }
  &.end{
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  &.cen{
    align-items: center;
    -webkit-align-items: center;
  }
  &.stretch{
    align-items: stretch;
    -webkit-align-items: stretch;
  }

  //&.wrap{
  //  flex-wrap: wrap;
  //  -ms-flex-wrap: wrap;
  //  -webkit-flex-wrap: wrap;
  //}
  &.wrap{
    flex-wrap: wrap;
    @include md{
      &-md{
        flex-wrap: wrap;
      }
    }
    @include sm{
      &-sm{
        flex-wrap: wrap;
      }
    }
    @include xs{
      &-xs{
        flex-wrap: wrap;
      }
    }
  }
  > *{
    max-width: 100%;
  }
}
.s-flex-start{
  @include align-self(flex-start);
}
