.order-confirmation-wrapper{
  .image-frame{
    position: relative;
    padding: 0 21px 29px 0;
    img{
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
      height: auto;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      border: 2px solid #425b98;
      @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
      top: 29px;
      left: 21px;
      right: 0;
      bottom: 0;
    }
  }
  @at-root .order-confirmation__heading{
    color: #333;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    border-bottom: none;
    text-align: center;
    margin: 1em 0 2.5em;
  }
  .box{
    background: transparent;
    border: none;
    padding: 6px 0;
    margin: 0 0 3em;
    color: #333333;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
  }
  .alert.alert-success{
    background-color: transparent;
    color: #333;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 0.2em;
    border: none;
    &:before{
      display: none;
    }
  }
  .cart_navigation{
    .button-medium{
      width: 300px;
      max-width: 100%;
    }
  }
}