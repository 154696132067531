//structure
#header {
  z-index: 5003;
  position: relative;
  background: $light-background;
}

.columns-container {
  background: $light-background;
}

#columns {
  position: relative;
  padding-bottom: 50px;
  padding-top: 15px;
}

#index {
  .tab-content {
    margin-top: 35px;
    margin-bottom: 34px;
  }
}

header {
  .banner {
    background: black;
    max-height: 100%;
    .row {
      margin: 0px;
    }
  }
  .xxs-x {
    @include xxs {
      justify-content: center !important;
    }
    .user-nav {
      .dropdown {
        &:after {
          @include xxs {
            display: none !important;
          }
        }
        @include xxs {
          margin-right:10px;
        }
        .dropdown-toggle {
          @include xxs {
            padding: 0;
          }
        }
      }
    }
  }
  .nav {
    box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.09);
    position: relative;
    z-index: 10;
    > *{
      @include transition(all .4s ease-in-out);
    }
    @at-root .is-menu-opened & > *{
      @include opacity(0);
      @include transform(translateX(250px));
    }
    .dropdown{
      margin-right: 15px;
    }
    &--item{
      font-size: 11px;
      line-height: 43px;
    }
    .row {
      margin: 0px;
    }
    nav {
      width: 100%;
    }
    a{
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
      color: #666666;
    }
    .dropdown-toggle{
      background: transparent;
      border-radius: 0;
      border: none;
      margin: 0;
      padding: 0 15px;
      line-height: 43px;
      text-transform: uppercase;
      color: #666666;
      @include xxs {
        display: 0;
      }
    }
    .caret{
      border: none;
      margin: 0 4px 0 5px;
      width: auto;
      height: auto;
      &:after{
        position: relative;
        top: -3px;
        @include arrow_border(down, 1px, 7px, 7px);
      }
    }
    .open{
      .dropdown-toggle{
        color: $crux-carrot-color;
      }
      .caret{
        &:after{
          top: 0;
          @include arrow_border(up, 1px, 7px, 7px);
        }
      }
    }
  }
}

.ie8 #header #header_logo {
  width: 350px;
}

// index page
.header-middle{
  background-color: #f8f8f8;
  position: relative;
  .header-middle--container{
    position: relative;
  }
  &.has-efrr{
    .header-middle__inner{
      padding: 0 0 0 5%;
      width: 100%;
      margin: 0 auto;
    }
    .sf-menu > li > ul{
      width: 1200px;
      max-width: calc(90vw);
    }
    .submenu-container.has-left-addition{
      padding-left: 240px;
    }
    .efrr-banner{
      padding: 0 4%;
      width: 696px;
      max-width: 696px / 1920px * 100%;
      a{
        display: block;
        &:focus, &:hover{
          outline: none;
          box-shadow: none;
        }
      }
      img{
        max-width: 100%;
        width: 550px;
        height: auto;
      }
    }
    @include md{
      .header-middle__inner {
        padding: 0 15px;
      }
      .efrr-banner {
        padding: 0 0 0 15px;
        width: 360px;
        max-width: 600px / 1920px * 100%;
      }
      .sf-menu > li > ul{
        width: 1200px;
        max-width: calc(100vw - 30px);
      }
      .submenu-container.has-left-addition{
        padding-left: 20vw;
      }
    }
    @include sm{
      .header-middle--container{
        width: 100%;
      }
      .efrr-banner{
        max-width: calc(100% + 30px);
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        text-align: center;
        background: #fff;
        img{
          width: 340px;
        }
      }
    }
  }
}
body.menu-fixed{
  .header-middle{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.09);
  }
  .header-middle__inner{
    padding: 0 15px;
    width: 1200px;
    max-width: 100%;
  }
  .efrr-banner{
    width: 315px;
    padding: 0 0 0 15px;
    @include sm{
      max-width: calc(100% + 30px);
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      padding: 0;
      background: #fff;
    }
  }
  #block_top_menu{
    height: 50px;
  }
  #header_logo img{
    height: 40px;
    width: auto;
  }
  header .nav{
    margin-bottom: 97px;
  }
}
#header_logo{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 144px;
  z-index: 4;
  a{
    display: block;
    max-width: 144px;
  }
  img{
    max-width: 144px;
    height: auto;
  }
}

// FOOTER
.footer-container {
  background: $crux-dark-blue;
  .container {
    padding-bottom: 15px;
  }
  #footer {
    color: #fff;
    .row {
      position: relative;
    }
    ul.row{
      position: static;
    }
    .footer-block {
      margin-top: 34px;
      // max 767px
      @media (max-width: $screen-xs-max) {
        margin-top: 20px;
      }
    }
    a {
      color: #fff;
      &:hover {
        color: $crux-carrot-color;
      }
    }
    h4 {
      text-align: center;
      color: #f5f5f5;
      font-size: 13px;
      font-weight: 300;
      line-height: 20px;
      text-transform: uppercase;
      // max 767px
      @media (min-width: $screen-sm){
        &:after{
          content: '';
          display: block;
          margin: 10px auto;
          border-bottom: 2px solid #e86342;
          width: 35px;
        }
      }
      @media (max-width: $screen-xs-max) {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 13px;
        &:after {
          display: block;
          content: "\f055";
          font-family: $font-icon;
          position: absolute;
          right: 0;
          top: 1px;
        }
        &.active:after {
          content: "\f056";
        }
      }
      a {
        color: #fff;
      }
    }
    ul {
      li {
        text-align: center;
        line-height: 24px;
        a {
          color: #ffffff;
          font-size: 11px;
          font-weight: 300;
        }
      }
    }
    #block_contact_infos {
      border-left: 1px solid #515151;
      // max 767px
      @media (max-width: $screen-xs-max) {
        border: none;
      }
      > div {
        padding: 0 0 0 5px;
        // max 767px
        @media (max-width: $screen-xs-max) {
          padding-left: 0;
        }
        ul {
          li {
            padding: 0 0 7px 4px;
            overflow: hidden;
            line-height: 30px;
            > span,
            > span a {
              color: $light-text-color;
              font-weight: normal;
            }
            i {
              font-size: 25px;
              width: 32px;
              text-align: center;
              padding-right: 12px;
              float: left;
              color: #908f8f;
            }
          }
        }
      }
    }
    .blockcategories_footer {
      clear: left;
    }

    .footer-logo{
      padding: 0 0 0 8px;
      float: left;
      .waynet{
        padding: 20px 0 0 15px;
        font-size: 10px;
      }
    }
    #social_block {
      float: right;
      width: auto;
      padding: 8px 0 0 10px;
      // max 767px
      @media (max-width: $screen-xs-max) {
        width: 100%;
        float: left;
        padding-top: 5px;
      }
      h3{
        margin: 10px 0 0;
      }
      ul {
        float: right;
        @media (max-width: $screen-xs-max) {
          float: none;
        }
        li {
          float: left;
          width: 30px;
          text-align: right;
          a {
            display: inline-block;
            color: $crux-carrot-color;
            font-size: 14px;
            @include opacity(1);
            // min 768px max 991px
            @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
              font-size: 14px;
            }
            span {
              display: none;
            }
            &:before {
              display: inline-block;
              font-family: $font-icon;
              vertical-align: -5px;
              font-weight: normal;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            &:hover {
              color: $light-text-color;
            }
          }
          &.facebook {
            a {
              &:before {
                content: "\f09a";
              }
            }
          }
          &.twitter {
            a {
              &:before {
                content: "\f099";
              }
            }
          }
          &.rss {
            a {
              &:before {
                content: "\f09e";
              }
            }
          }
          &.youtube {
            a {
              &:before {
                content: "\f167";
              }
            }
          }
          &.google-plus {
            a {
              &:before {
                content: "\f0d5";
              }
            }
          }
          &.pinterest {
            a {
              &:before {
                content: "\f0d2";
              }
            }
          }
          &.vimeo {
            a {
              &:before {
                content: "\f194";
              }
            }
          }
          &.instagram {
            a {
              &:before {
                content: "\f16d";
              }
            }
          }
        }
      }
      h4 {
        float: right;
        margin-bottom: 0;
        font-size: 21px;
        line-height: 25px;
        text-transform: none;
        padding: 0 10px 0 0;
        // max 767px
        @media (max-width: $screen-xs-max) {
          display: none;
          text-align: center;
        }
      }
    }
    .bottom-footer {
      position: absolute;
      bottom: -55px;
      left: 0;
      div {
        padding: 15px 0 0 0;
        border-top: 1px solid #515151;
        width: 100%;
      }
    }
  }
}