a:hover {
  text-decoration: none;
}

@media only screen and (min-width: 1200px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

body {
  min-width: 320px;
  height: 100%;
  line-height: 18px;
  font-size: 13px;
  overflow-x: hidden;
  color: $base-body-color;
  &.content_only {
    background: none;
    min-width: 0;
  }
}

body, html{
  .scroll-lock{
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
  }
}
textarea {
  resize: none;
}