//buttons
input.button_mini,
input.button_small,
input.button,
input.button_large,
input.button_mini_disabled,
input.button_small_disabled,
input.button_disabled,
input.button_large_disabled,
input.exclusive_mini,
input.exclusive_small,
input.exclusive,
input.exclusive_large,
input.exclusive_mini_disabled,
input.exclusive_small_disabled,
input.exclusive_disabled,
input.exclusive_large_disabled,
a.button_mini,
a.button_small,
a.button,
a.button_large,
a.exclusive_mini,
a.exclusive_small,
a.exclusive,
a.exclusive_large,
span.button_mini,
span.button_small,
span.button,
span.button_large,
span.exclusive_mini,
span.exclusive_small,
span.exclusive,
span.exclusive_large,
span.exclusive_large_disabled {
  position: relative;
  display: inline-block;
  padding: 5px 7px;
  font-weight: 300;
  color: #fff;
  text-shadow: 0 3px 3px rgba(51, 51, 51, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #e86342;
  cursor: pointer;
  white-space: normal;
  text-align: left;
  &:focus{
    text-decoration: none;
  }
}

*:first-child+html input.button_mini,
*:first-child+html input.button_small,
*:first-child+html input.button,
*:first-child+html input.button_large,
*:first-child+html input.button_mini_disabled,
*:first-child+html input.button_small_disabled,
*:first-child+html input.button_disabled,
*:first-child+html input.button_large_disabled,
*:first-child+html input.exclusive_mini,
*:first-child+html input.exclusive_small,
*:first-child+html input.exclusive,
*:first-child+html input.exclusive_large,
*:first-child+html input.exclusive_mini_disabled,
*:first-child+html input.exclusive_small_disabled,
*:first-child+html input.exclusive_disabled,
*:first-child+html input.exclusive_large_disabled {
  border: none;
}

//hover button
input.button_mini:hover,
input.button_small:hover,
input.button:hover,
input.button_large:hover,
input.exclusive_mini:hover,
input.exclusive_small:hover,
input.exclusive:hover,
input.exclusive_large:hover,
a.button_mini:hover,
a.button_small:hover,
a.button:hover,
a.button_large:hover,
a.exclusive_mini:hover,
a.exclusive_small:hover,
a.exclusive:hover,
a.exclusive_large:hover {
  text-decoration: none;
  background-position: left -50px;
}

//active button
input.button_mini:active,
input.button_small:active,
input.button:active,
input.button_large:active,
input.exclusive_mini:active,
input.exclusive_small:active,
input.exclusive:active,
input.exclusive_large:active,
a.button_mini:active,
a.button_small:active,
a.button:active,
a.button_large:active,
a.exclusive_mini:active,
a.exclusive_small:active,
a.exclusive:active,
a.exclusive_large:active {
  background-position: left -100px;
}

input.button_disabled,
input.exclusive_disabled,
span.exclusive {
  border: 1px solid #cccccc;
  color: #999999;
  background: url(../img/bg_bt_2.gif) repeat-x 0 0 #cccccc;
  cursor: default;
}

.btn.disabled,
.btn[disabled]:hover {
  opacity: 0.3;
}

.button.button-default{
  @include box-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #e86342;
  border: none;
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  span{
    padding: 0;
  }
}

.button.button-small {
  font: bold 13px / 17px $font-family;
  color: $button-text-color;
  background: $button-small-bg;
  border: 1px solid;
  border-color: #666666 #5f5f5f #292929 #5f5f5f;
  padding: 0;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.24);
  @include border-radius(0);
  span {
    display: block;
    padding: 3px 8px 3px 8px;
    border: 1px solid;
    border-color: #8b8a8a;
    i {
      vertical-align: 0px;
      margin-right: 5px;
      &.right {
        margin-right: 0;
        margin-left: 5px;
      }
    }
    &:hover {
      background: $button-small-hover-bg;
      border-color: #303030 #303030 #666666 #444444;
    }
  }
}

.button.button-medium {
  font-size: 14px;
  line-height: 21px;
  color: $button-text-color;
  font-weight: 300;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #e86342;
  border: 1px solid #e86342;
  text-align: center;
  @include border-radius(0);
  padding: 9px 25px;
  text-transform: uppercase;
  @media (max-width: $screen-xs) {
    padding: 9px 15px;
  }
  span {
    i {
      &.left {
        font-size: 24px;
        vertical-align: -2px;
        margin: -4px 10px 0 0;
        display: inline-block;
        // max 480px
        @media (max-width: $screen-xs) {
          margin-right: 5px;
        }
      }
      &.right {
        margin-right: 0;
        margin-left: 9px;
        // max 480px
        @media (max-width: $screen-xs) {
          margin-left: 5px;
        }
      }
    }
  }
  &:hover {
    background-color: #ca6141;
  }
}

.button.button-medium.exclusive {
  border: 1px solid #e86342;
  background: #fff;
  color: #e86342;
  &:hover {
    background: #e86342;
    color: #fff;
  }
}

.link-button {
  font-weight: bold;
  i {
    margin-right: 5px;
    &.large {
      font-size: 26px;
      line-height: 26px;
      vertical-align: -3px;
      color: silver;
    }
  }
}

.btn.button-plus,
.btn.button-minus {
  font-size: 14px;
  line-height: 14px;
  color: $button-pm-color;
  text-shadow: 1px -1px rgba(0, 0, 0, 0.05);
  padding: 0;
  border: 1px solid;
  border-color: #dedcdc #c1bfbf #b5b4b4 #dad8d8;
  @include border-radius(0);
  span {
    display: block;
    border: 1px solid $light-border-color;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    padding: 4px 0 0 0;
    background: rgb(255, 255, 255);
    @include gradient-vertical(rgba(255, 255, 255, 1), rgba(251, 251, 251, 1));
  }
  &:hover {
    color: $base-text-color;
    span {
      filter: none;
      background: #f6f6f6;
    }
  }
}

.button.exclusive-medium {
  font-size: 14px;
  line-height: 21px;
  color: #1d314b;
  font-weight: 300;
  text-transform: uppercase;
  border: 2px solid #1d314b;
  background-color: #ffffff;
  padding: 8px 10px;
  text-shadow: 1px 1px rgba(255, 255, 255, 1);
  @include box-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
  span {

  }
  &:hover{
    background-color: #f7f7f7;
  }
}

.button.ajax_add_to_cart_button {
  border: 1px solid $crux-carrot-color;
  background-color: $crux-carrot-color;
  color: $light-text-color;
  text-transform: uppercase;
  line-height: 38px;
  @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  white-space: nowrap;
  padding: 0 10px;
  width: 196px;
  font-size: 14px;
  text-align: center;
  &:hover{
    border: 1px solid $crux-carrot-color;
    background-color: $crux-carrot-color;
    color: $light-text-color;
  }
}

.button.lnk_view {
  font: 700 17px / 21px $font-family;
  color: $base-text-color;
  text-shadow: 1px 1px rgba(255, 255, 255, 1);
  padding: 0;
  border: 1px solid;
  border-color: #cacaca #b7b7b7 #9a9a9a #b7b7b7;
  span {
    border: 1px solid;
    border-color: $light-border-color;
    padding: 10px 14px;
    display: block;
    background: rgb(247, 247, 247);
    @include gradient-vertical(rgba(247, 247, 247, 1), rgba(237, 237, 237, 1));
  }
  &:hover {
    border-color: #9e9e9e #9e9e9e #c8c8c8 #9e9e9e;
    span {
      filter: none;
      background: #e7e7e7;
    }
  }
  i.left {
    padding-right: 8px;
    color: #777777;
  }
}
