@import "compass";
@import "theme_variables";
//#my-account {
//	.addresses-lists {
//		margin-bottom: 30px;
//	}
//	ul.myaccount-link-list {
//		li {
//			overflow: hidden;
//			padding-bottom: 10px;
//			a {
//				display: block;
//				overflow: hidden;
//				font: 600 16px/20px $font-custom;
//				color: #555454;
//				text-shadow: 0px 1px rgba(255,255,255,1);
//				text-transform: uppercase;
//				text-decoration: none;
//				position: relative;
//				border: 1px solid;
//				border-color: #cacaca #b7b7b7 #9a9a9a #b7b7b7;
//				@include background-image(linear-gradient(rgb(247,247,247), rgb(237,237,237)));
//				@include border-radius(4px);
//				i {
//					font-size: 25px;
//					color: #fd7e01;
//					position: absolute;
//					left: 0;
//					top: 0;
//					width: 52px;
//					height: 100%;
//					padding: 10px 0 0 0;
//					text-align: center;
//					border: 1px solid $light-border-color;
//					@include border-top-left-radius(4px);
//					@include border-bottom-left-radius(4px);
//				}
//				span {
//					display: block;
//					padding: 13px 15px 15px 17px;
//					overflow: hidden;
//					border: 1px solid;
//					margin-left: 52px;
//					border-color: $light-border-color $light-border-color $light-border-color #c8c8c8;
//					@include border-top-right-radius(5px);
//					@include border-bottom-right-radius(5px);
//				}
//				&:hover {
//					filter: none;
//					background: #e7e7e7;
//					border-color: #9e9e9e #c2c2c2 #c8c8c8 #c2c2c2;
//				}
//			}
//		}
//	}
//}

#my-account-menu {
	@media (min-width: $screen-md-max) {
		padding-right: 0;
		padding-left: 0;
	}
	.sg-myaccount-lsidebar {
		.sg-group-heading {
			h1.page-heading {
				text-align: center;
				border: none;
				font-size: 23px !important;
				margin: 8px 0 38px 0 !important;
			}
		}
		ul.nav-tabs {
			border-bottom: 2px solid #f8f8f8;
			@media (min-width: $screen-lg) {
				padding-left: 84px;

			}

			li {
				@media (max-width: $screen-sm-max) {
					width: 100%;
				}
				&:first-of-type {
					a {
						border-left: 1px;
						border-style: solid;
						border-color: #eeeeee;
					}
				}
				margin: 0;
				background-color: #f8f8f8;
				&.active {
					a {
						color: #333333;
						background-color: #ffffff;
						border-top: 1px;
						border-right: 1px;
						border-bottom: 0;
						border-left: 1px;
						border-style: solid;
						border-color: #000;
						@media (max-width: $screen-sm-max) {
							& {
								border-bottom: 1px;
								border-style: solid;
							}
						}
					}
					a:after {
						content: ' ';
						width: 101%;
						height: 2px;
						background-color: #FFF;
						top: 100%;
						left: -1px;
						position: absolute;
						border-left: 1px solid #000;
						border-right: 1px solid #000;

						@media (max-width: $screen-sm-max) {
							& {
								display: none;
							}
						}
					}

				}
				a {
					padding: 11px 34px;
					margin: 0;
					color: #a6a6a6;
					font-family: $font-custom;
					font-size: 14px;
					font-weight: 600;
					line-height: 36px;
					text-transform: uppercase;
					background-color: #f8f8f8;
					border-top: 1px;
					border-right: 1px;
					border-bottom: 0;
					border-left: 0px;
					border-style: solid;
					border-color: #eeeeee;
					letter-spacing: -0.7px;
				}
			}
		}
	}
}

//To później to stylów addresses
.my-account-content {
	#add_address {
		div.form-group, .inline-infos, .submit2 {
			text-align: center;
			width: 100%;
			input, .selector, textarea {
				margin: 0 auto;
			}
		}
		@media (max-width: $screen-xs-max) {
			.form-control {
				width: 100%;
			}
		}
	}
	.required {
		text-align: center;
	}
	.addresses {
		.bloc_adresses {
			display: flex;
			align-items: stretch;
			flex-wrap: wrap;
			.address {
				@media (min-width: $screen-lg) {
					margin-right: 15px;
				}
				@media (max-width: $screen-xs-max) {
					margin-bottom: 15px
				}

				.box {
					border: 1px solid #dcdcdc;
					background-color: #ffffff;
					padding: 10px 0px 0;
					margin: 0;
					@media (min-width: $screen-lg) {
						width: 255px;
					}
					li:not(:last-of-type) {
						padding: 0 17px;
						h3.page-subheading {
							color: #333333;
							font-family: $font-custom;
							font-size: 14px;
							font-weight: 600;
							line-height: 36px;
							text-transform: uppercase;
							margin: 0;
							padding: 0;
							border: none;
							letter-spacing: -0.7px;
							margin-bottom: 14px;
						}
						span {
							color: #333333;
							font-family: $font-custom;
							font-size: 14px;
							font-weight: 300;
							line-height: 20px;
							text-transform: uppercase;
						}
					}
					li.address_update {
						text-align: center;
						display: flex;
						justify-content: space-between;
						padding: 0 9px 0 17px;

						margin-bottom: 10px;
						align-items: center;
						a {
							margin: 0px;
						}
						a.address-plain-link {
							font-size: 12px;
							font-weight: 300;
							line-height: 20px;
							text-decoration: underline;
							font-family: $font-custom;
							text-transform: lowercase;
						}
					}
				}
			}
		.add-new-block {
			display: flex;
			align-items: center;
			justify-content: center;
		}
			.add-new-address-btn {
				color: #333333;
				font-family: $font-custom;
				font-size: 14px;
				font-weight: 600;
				line-height: 36px;
				text-transform: uppercase;
				position: relative;
				&:before {
					content: url(../img/plus.png);
					position: absolute;
					left: -45px;
				}
			}
		}
	}
	@media (min-width: $screen-md-max) {
		padding-right: 0;
		padding-left: 0;
	}
	padding-top: 51px;
	//To później do stylów dla historii
	#block-history {
		#order-list {
			color: #333333;
			font-family: $font-family-base;
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
			thead {
				tr {
					th {
						text-align: center;
						color: #333333;
						font-family: $font-custom;
						font-size: 14px;
						font-weight: 400;
						text-transform: uppercase;
						background: none;
						padding: 10px;
						width: 17%;
						line-height: 18px;
						letter-spacing: 0px;
						@media (max-width: $screen-xs-max) {
							line-height: 16px;
							padding: 5px;
						}
					}
					td {
						padding: 12px;
						@media (max-width: $screen-xs-max) {
							padding: 5px;
						}
					}
				}
			}
			tbody {
				tr {
					td {
						padding: 12px;
						@media (max-width: $screen-xs-max) {
							padding: 5px;
						}
						.color-myaccount {
							color: #000;
							text-decoration: none;
						}
					}
				}
			}
			.footable-sort-indicator {
				display: none;

			}
			.incl-price-span, .incl-price-span + span {
				font-weight: bold;
			}
			.excl-price-p {
				font-size: 12px;
				font-weight: 300;
				line-height: 30px;
				color: #989898;
			}
			.text-left {
				text-align: left;
			}
			.text-right {
				text-align: right;
			}
			.text-center {
				text-align: center;
			}
		}
	}
	.discount {
		color: #333333;
		font-family: $font-custom !important;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		border: none;
		tbody {
			tr {
				td {
					width: 50%;
					border: none;
					&.discount_description,
					&.discount_value,
					&.discount_freeshipping,
					&.discount_freeshipping-price {
						color: #333333;
						font-family: $font-custom;
						font-size: 14px;
						font-weight: 300;
						line-height: 36px;
						text-transform: uppercase;
					}
					&.discount_value,
					&.discount_freeshipping_price {
						font-weight: bold;
						font-size: 17px;
					}
				}
			}
		}
		.footable-sort-indicator {
			display: none;

		}
		.incl-price-span {
			font-weight: bold;
		}
		.text-left {
			text-align: left;
		}
		.text-right {
			text-align: right;
		}
		.text-center {
			text-align: center;
		}
	}
	.identity-box {

			span.input-holder-identity {
				@media (max-width: $screen-xs-max) {
					width: 100%;
					display: block;
					input {
						max-width: 100% !important;
						width: 100%;
					}
				}
				input {
					border-color: #b7b7b7;;
					color: #000000;
				}

			}

		background: none;
		border: none;
		div.form-group {
			display: flex;
			justify-content: center;
			.button-medium {
				width: 300px;
				@media (max-width: $screen-xs-max) {
					max-width: 100% !important;
					width: 100%;
				}
			}
		}
		form > fieldset > fieldset > div.form-group > span > label {
			color: #333333;
			font-family: $font-custom;
			font-size: 12px;
			font-weight: 300;
			line-height: 16px;
			text-indent: 8px;
			&:before {
				display: none;
			}
		}

		fieldset > legend {
			text-align: center;
			color: #333333;
			font-family: $font-custom;
			font-size: 12px;
			font-weight: 600;
			line-height: 36px;
			text-transform: uppercase;
			border: none;
		}

	}
}

.footer_links_plain {
	margin-top: 68px;
	li {
		a {
			color: #a5a5a5;
			font-family: $font-custom;
			font-size: 12px;
			font-weight: 300;
			line-height: 20px;
			text-decoration: underline;
		}
	}
}

