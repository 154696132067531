@import 'bootstrap_lib/_variables';

//------- FONTS
$kaleko:									"Kaleko 105 Round W01 Book", Helvetica, sans-serif;
$font-family-sans-serif: 	$kaleko;
$font-family-base:				$kaleko;
$font-family:				  		$kaleko;
$font:						  		normal 11px/16px $font-family;
$font-custom:                 		"Kaleko 105 Round W01 Book", sans-serif;
$font-icon:                   		"FontAwesome";
$fa-css-prefix:       				icon !default;


//-------- BORDERS
$base-border-color:					#d6d4d4;
$main_border:						1px solid $base-border-color;
$dark-border-color:					#333;
$light-border-color:				#fff;
$homepage-footer-border:            #d9d9d9;

//--------- BACKGROUNDS
$base-box-bg:                       #fbfbfb;
$table-background:                  $base-box-bg;
$dark-background:                   #333;
$light-background:                  #fff;
$homepage-footer-bg:                #f2f2f2;

//--------- COLORS
$base-body-color:                   #777;
$base-text-color:                   #333;
$base-heading-color:				#555454;
$base-title-color:					$base-text-color;
$light-text-color:                  #fff;
$link-color:						$link-color;
$link-hover-color:                  $link-hover-color;
$description-color:					#666;


//--------- Indents
$base-page-indent:                 	$grid-gutter-width;
$base-box-padding:                  14px 18px 13px;
$base-box-indent:                  	0 0 30px 0;

//---------- Product Elements
$product-name-color:                #333;
$product-name-hover-color:          #515151;
$price-color:						$base-body-color;
$product-price-color:				$base-text-color;
$product-reduced-price:				#f00;
$product-special-price-color:		#f13340;
$product-old-price-color:			#6f6f6f;

//---------- Buttons
$button-text-color:					$light-text-color;

		//------ Buttons +/- ---
		$button-pm-color:			#c0c0c0;
		$button-pm-hover-color:		$base-text-color;

		//------ Button Small --
		$button-small-bg:			#6f6f6f;
		$button-small-hover-bg:		#575757;

		//-------Button exclusive-medium
		$button-em-color:			$base-text-color;

//---------- Product Listing
$base-listing-border-color:					$base-border-color;
$base-listing-grid-padding:					$base-box-padding;

		//-------------- Grid/List vars
		$GL-text-color:								#808080;
		$GL-title-color:							$base-text-color;
		$GL-icon-color:								#e1e0e0;
		$GL-icon-color-hover:						$base-text-color;

		//-------------- Pagination vars
		$pagination-prevnext-color:					#777676;
		$pagination-bgcolor:	               		$base-box-bg;
		$pagination-hover-bg:						#f6f6f6;

//---------- Product Info
$top-line-color:									#C4C4C4;
$product-image-border:								#dbdbdb;
$scroll-button-color:								#c0c0c0;
$list-info-bg:										#f6f6f6;
$scroll-button-hover-color:							$base-text-color;
$tr-even-bg:										#fdfdfd;

//------------- Project specific variables
$crux-carrot-color:					#e86342;
$crux-blue:					#385F96;
$crux-footer-color:					#1D314C;
$crux-dark-blue:						#1D314C;

//sub heading (h2,h3) define
@mixin sub-heading {
	color: $base-heading-color;
	text-transform: uppercase;
	font-family: $font-custom;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 20px 17px;
	margin: 0 0 20px;
	position: relative;
	border: 1px solid $base-border-color;
	border-bottom: none;
	background: $base-box-bg;
}

/* mixins */
@mixin w-placeholder{
	&::-moz-placeholder{ @content }
	&:-ms-input-placeholder{ @content }
	&::-webkit-input-placeholder{ @content }
}
@mixin absolute-stretch{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
@mixin triangle($direction, $w: 10px, $h: 10px, $va: 0px , $color: currentcolor) {
	content: '';
	display: inline-block;
	vertical-align: $va;
	width: 0;
	height: 0;
	border-style: solid;

	@if $direction == up{
		border-width: 0 $w/2 $h $w/2;
		border-color: transparent transparent $color;
	} @else if $direction == down{
		border-width: $h $w/2 0 $w/2;
		border-color: $color transparent transparent;
	} @else if $direction == right{
		border-width: $h/2 0 $h/2 $w;
		border-color: transparent transparent transparent $color;
	} @else if $direction == left{
		border-width: $h/2 $w $h/2 0;
		border-color: transparent $color transparent transparent;
	}
}
@mixin hv_center(){
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
	max-width: 100%;
	max-height: 100%;
}
/* Custom mixins */
@mixin arrow_border($direction, $thick: 2px, $w: 10px, $h: 10px, $va: 0px , $color: currentcolor) {
	border-style: solid;
	border-color: $color;
	border-width: $thick $thick 0 0;
	display: inline-block;
	background: transparent;
	content: "";
	vertical-align: $va;
	width: $w;
	height: $h;
	transform-origin: 50% 50%;
	@if $direction == up{
		@include transform(rotate(-45deg));
	} @else if $direction == down{
		@include transform(rotate(135deg));
	} @else if $direction == right{
		@include transform(rotate(45deg));
	} @else if $direction == left{
		@include transform(rotate(-135deg));
	}
}

/* inspired by bootstrap */

$screen-md: 1200px;
$screen-sm: 992px;
$screen-xs: 768px;
$screen-xxs: 480px;

$screen-md-max: 1199px;
$screen-sm-max: 991px;
$screen-xs-max: 767px;
$screen-xxs-max: 479px;


@mixin md {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}

@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}

@mixin xxs {
	@media (max-width: #{$screen-xxs-max}) {
		@content;
	}
}